import react from "react";

function Navs() {
  return (
    <>
      <div
        class="navbar custom-navbar wow fadeInDown"
        data-wow-duration="2s"
        role="navigation"
      >
        <div class="container">
          <div class="navbar-header">
            <button
              class="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              {" "}
              <span class="icon icon-bar"></span>{" "}
              <span class="icon icon-bar"></span>{" "}
              <span class="icon icon-bar"></span>{" "}
            </button>
            <a href="index-2.html" class="navbar-brand">
              <img src="images/logo-color.png" alt="" />
            </a>{" "}
          </div>

          <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
              <li class="dropdown active">
                <a href="/">
                  Home <span class="caret"></span>
                </a>
              </li>
              <li>
                <a href="about-us.html">About</a>
              </li>
             
              <li>
                <a href="/contacte">Contact</a>
              </li>
              <li>
                <span class="calltxt">
                  <i class="fa fa-phone" aria-hidden="true"></i> +33 78 09 33 687
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Navs