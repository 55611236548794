import React, { useState, useEffect } from "react";
import "./testmonial.css";
import { db } from "../firebase"; // Importez la configuration Firebase
import { ref, get } from "firebase/database";
import { Link } from "react-router-dom";

function Team({ member }) {
  const [teamMembers, setTeamMembers] = useState([]); // État pour stocker les membres de l'équipe

  useEffect(() => {
    // Référence vers la base de données où les membres de l'équipe sont stockés
    const teamRef = ref(db, "team"); // Assurez-vous que votre chemin est correct (ici "team" est un exemple)

    // Récupérer les données de la base de données Firebase
    get(teamRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val(); // Données récupérées
          const membersArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key], // Ajoute les données de chaque membre
          }));
          setTeamMembers(membersArray); // Met à jour l'état avec les données
        } else {
          console.log("Aucun membre trouvé dans la base de données");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des membres:", error);
      });
  }, []);

  const testimonialsData = [
    {
      quote:
        "Grâce à Francisco Dasilva, j'ai pu résoudre mon litige rapidement et efficacement. Leur équipe est professionnelle et attentive à mes besoins",
      name: "John frederic",
      position: "CEO - Company Inc",
    },
    {
      quote:
        "Je tiens à remercier Francisco Dasilva pour son intervention rapide et efficace dans la résolution de mon litige. Leur équipe a su faire preuve de professionnalisme et d'écoute, répondant parfaitement à mes attentes.",
      name: "Alice Johnson",
      position: "Marketing Director - Media Ltd",
    },
    {
      quote:
        "Je recommande vivement ce cabinet d'avocats. Ils m'ont aidé à travers un processus complexe avec beaucoup de patience et d'expertise",
      name: "Robert Brown",
      position: "CTO - Innovators Inc",
    },
  ];
  return (
    <>
      <div id="team" class="parallax-section">
        <div class="container">
          <div class="section-title">
            <h3>
              Nos avocats
              <span>L'équipe</span>
            </h3>
            <p>
              Notre équipe d'avocats spécialisés est à votre service pour vous
              accompagner dans les moments les plus importants de votre vie. Que
              vous soyez confronté à des problèmes juridiques personnels,
              familiaux ou criminels, chaque membre de notre équipe est dévoué à
              défendre vos droits et à vous fournir les conseils les plus
              avisés. Découvrez nos experts et leur expertise qui feront de
              votre cause leur priorité.
            </p>
          </div>
          <div className="row">
            {teamMembers.map((member) => (
              <div key={member.id} className="col-md-3 col-sm-6">
                <div className="team-thumb">
                  <div className="thumb-image">
                    <img
                      src={`images/team/${member.imageUrl}`} // Utilisez des backticks pour interpoler la variable `member.image`
                      className="animate"
                      alt={member.name}
                    />
                  </div>
                  <Link
                    to={`/team/${member.name}`}
                    style={{ textDecoration: "none" }}
                  >
                    <h4>{member.name}</h4>
                    <h5>{member.position}</h5>
                  </Link>
                  <ul className="list-inline social">
                    <li>
                      <a
                        href={member.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-twitter"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={member.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-facebook"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-linkedin"
                      >
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="testimonials">
        <div className="container">
          <div className="section-title">
            <h3>Témoignages</h3>
            <p>Ce que nos clients disent de nous</p>
          </div>
          <ul className="testimonialsList">
            {testimonialsData.map((testimonial, index) => (
              <li key={index} className="item">
                <p>{testimonial.quote}</p>
                <div className="clientname">{testimonial.name}</div>
                <div className="clientinfo">{testimonial.position}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Team;
