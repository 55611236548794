import React from "react";
import "animate.css";

const PracticeArea = () => {
  const services = [
    {
      img: "images/lawyer/1.jpg",
      title: "Droit de la famille (Divorce)",
      description:
        "Le droit de la famille traite des questions juridiques liées au mariage, au divorce, à la garde des enfants et aux pensions alimentaires. Nous vous accompagnons dans les moments difficiles de la séparation, en protégeant vos droits et en veillant à ce que vos intérêts et ceux de vos enfants soient préservés.",
    },
    {
      img: "images/lawyer/2.jpg",
      title: "Contentieux (Litigation)",
      description:
        "Le contentieux désigne les litiges qui nécessitent une action en justice. Que ce soit pour défendre vos droits ou résoudre des différends, nos avocats spécialisés vous assistent à chaque étape du processus judiciaire, en veillant à ce que vos intérêts soient efficacement représentés devant le tribunal.",
    },
    {
      img: "images/lawyer/3.jpg",
      title: "Droit des accidents (Accident Law)",
      description:
        "Si vous avez été victime d'un accident, que ce soit sur la route, au travail ou dans un autre contexte, nos avocats spécialisés en droit des accidents vous aideront à obtenir une indemnisation juste. Nous vous accompagnons dans la gestion de vos blessures physiques et morales et vous assistons dans vos démarches légales.",
    },
    {
      img: "images/lawyer/4.jpg",
      title: "Droit des drogues (Drug Law)",
      description:
        "Le droit des drogues couvre les infractions liées à l’usage, à la possession, au trafic et à la vente de substances illicites. Nous offrons des conseils juridiques spécialisés pour ceux qui se trouvent confrontés à des accusations de crimes liés aux drogues, en veillant à garantir une défense efficace et à préserver vos droits.",
    },
    {
      img: "images/lawyer/5.jpg",
      title: "Droit personnel (Personal Law)",
      description:
        "Le droit personnel englobe des questions juridiques touchant à la vie privée, à la protection des individus et à leurs biens. Nos avocats vous aident à résoudre des problèmes liés à la vie personnelle, comme les testaments, les successions, les droits de propriété ou la protection contre la violence domestique.",
    },
    {
      img: "images/lawyer/6.jpg",
      title: "Droit pénal (Criminal Law)",
      description:
        "Le droit pénal concerne les infractions et les peines applicables en cas de violation de la loi. Si vous êtes accusé d'un crime, nos avocats pénalistes sont à votre disposition pour vous défendre. Nous vous aidons à travers toutes les étapes de la procédure pénale, en vous assurant que vos droits sont respectés et en cherchant la meilleure défense possible.",
    },
  ];

  return (
    <div>
      <div id="practicearea" className="parallax-section">
        <div className="container">
          {/* Section Title */}
          <div className="section-title">
            <h3>
              Nos domaines <span>d'expertise</span>
            </h3>
            <p>
              Notre équipe est composée d'avocats spécialisés dans différents
              secteurs du droit. Chaque membre de notre équipe met son expertise
              à votre service pour défendre vos intérêts.
            </p>
          </div>
          <div className="row">
            {services.map((service, index) => (
              <div
                className="col-md-4 col-sm-6"
                key={index}
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`}
              >
                <div className="service-thumb">
                  <div className="thumb-img">
                    <img
                      src={service.img}
                      className="animate__animated animate__fadeIn"
                      alt={service.title}
                    />
                  </div>
                  <h4>{service.title}</h4>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
            
      </div>
    </div>
  );
};

export default PracticeArea;
