import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Importez la configuration Firebase
import { ref, get } from "firebase/database";
import { useParams } from "react-router-dom"; // Pour obtenir le paramètre de l'URL
import { Helmet } from 'react-helmet-async';

function TeamMemberDetails() {
  const { name } = useParams(); // Récupère le nom du membre de l'équipe à partir de l'URL
  const [member, setMember] = useState(null); // État pour stocker les données du membre
  const [error, setError] = useState(""); // État pour gérer les erreurs

  useEffect(() => {
    console.log("Nom du membre passé dans l'URL:", name); // Log du nom du membre

    // Référence vers la base de données où les membres de l'équipe sont stockés
    const memberRef = ref(db, `team/${name}`); // Utilisation du "name" pour récupérer le membre

    console.log("Référence Firebase pour le membre:", memberRef); // Log de la référence Firebase

    // Récupérer les données du membre de l'équipe à partir de Firebase
    get(memberRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val(); // Données du membre récupérées
          setMember(data); // Met à jour l'état avec les détails du membre
          console.log("Données récupérées du membre:", data); // Log des données récupérées
        } else {
          setError("Membre non trouvé"); // Message d'erreur si le membre n'existe pas
          console.log("Aucun membre trouvé pour cette clé dans Firebase");
        }
      })
      .catch((error) => {
        setError("Erreur lors de la récupération des données: " + error.message); // Affiche l'erreur exacte
        console.log("Erreur Firebase:", error.message);
      });
  }, [name]); // Recharger les données chaque fois que le "name" change

  if (error) {
    return <div>{error}</div>; // Affiche l'erreur s'il y en a
  }

  if (!member) {
    return <div>Chargement des détails du membre...</div>; // Affiche un message de chargement si les données ne sont pas encore disponibles
  }

  return (
    <div>
        <div>
      <Helmet>
        <title>Page Titre Optimisé SEO</title>
        <meta name="description" content="Ceci est une description optimisée pour le référencement." />
        <meta name="keywords" content="React, SEO, référencement, optimisation" />
        <meta name="author" content="Votre Nom" />
        <meta property="og:title" content="Titre pour réseaux sociaux" />
        <meta property="og:description" content="Description pour réseaux sociaux" />
        <meta property="og:image" content="/chemin/vers/image.png" />
      </Helmet>
      <h1>Bienvenue sur la page optimisée SEO</h1>
    </div>
    <div className="team-member-details">
      <h1>Détails du Membre de l'Équipe</h1>
      <div className="member-info">
        <h2>{member.name}</h2>
        <p><strong>Position:</strong> {member.position}</p>
        <p><strong>Email:</strong> {member.email}</p>
        <p><strong>Téléphone:</strong> {member.phone}</p>
        <p><strong>Bio:</strong> {member.bio}</p>
      </div>
    </div>
    </div>
  );
}

export default TeamMemberDetails;
