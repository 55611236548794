import react from "react";

function Admin() {
    return(
        <>
        
        
        </>
    );
    
}

export default Admin;