import React, { useState } from 'react';
import axios from 'axios';

function Contact() {
  const [formData, setFormData] = useState({
    email: '',
    name:'',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://cabinetbackend.onrender.com/submit-contact', formData);
      alert('Email envoyé avec succès');
      console.log(response.data);
    } catch (error) {
      alert('Erreur lors de l\'envoi de l\'email');
      console.error(error);
    }
  };
  return (
    <>
      <div class="pageTitle">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <h1 class="page-heading">Nous contacter</h1>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="breadCrumb">
                <a href="/">Acceuil</a> / <span>Nous contacter</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="map"></div>

      <div id="contact" class="parallax-section">
  <div class="container"> 
  <div class="section-title">
      <h3>Obtenir un devis gratuit</h3>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="contact-now">
          <div class="contact"> <span><i class="fa fa-home"></i></span>
            <div class="information"> <strong>Address:</strong>
              <p>Pologne 19 Jana Pawła II Radzyń Podlaski, Voïvodie de Lublin</p><br/>
              <p>13 rue Dembour 57000 Metz France</p>
            </div>
          </div>
          <div class="contact"> <span><i class="fa fa-envelope"></i></span>
            <div class="information"> <strong>Email Address:</strong>
              <p>contact@cabinet-juridique.online</p>
              <p>support@cabinet-juridique.online</p>
            </div>
          </div>

          <div class="contact"> <span><i class="fa fa-phone"></i></span>
            <div class="information"> <strong>Telephone:</strong>
              <p>+44 73212329</p>
              <p>+33780933687 </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8"> 
        <div class="contact-form">
          <form onSubmit={handleSubmit} class="row">
            <div class="col-md-4 col-sm-6">
              <input type="email" name="email" value={formData.email} onChange={handleChange} class="form-control"  placeholder="Email" required/>
            </div>
            <div class="col-md-4 col-sm-12">
              <input name="name" value={formData.name} onChange={handleChange}  class="form-control" placeholder="Nom et Prenom" required />
            </div>
            <div class="col-md-12 col-sm-12">
              <input type="text" name="subject" value={formData.subject} onChange={handleChange}  class="form-control" placeholder="subject" required />
            </div>
            <div class="col-md-12 col-sm-12">
              <textarea class="form-control" rows="5" name="message" value={formData.message} onChange={handleChange} placeholder="Message"></textarea>
            </div>
            <div class="col-md-12">
              <button id="submit" type="submit" class="form-control" name="submit">Envoyer Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  );
}

export default Contact;
