import React, { useState } from 'react';
import { app, db } from '../firebase';  // Utilisez des imports nommés
import { getDatabase, ref, set, push } from "firebase/database";
import { useNavigate } from 'react-router-dom';

function AddTestimonial() {

  const navigate = useNavigate();

  // Initialiser les valeurs d'état pour les champs de formulaire
  let [name, setName] = useState("");
  let [testimonial, setTestimonial] = useState("");

  const saveTestimonial = async () => {
    const db = getDatabase(app);
    const newTestimonialRef = push(ref(db, "testimonials"));
    
    // Enregistrer le témoignage dans la base de données Firebase
    set(newTestimonialRef, {
      name: name,
      testimonial: testimonial,
      date: new Date().toISOString() // Ajoute la date de soumission du témoignage
    }).then(() => {
      alert("Témoignage enregistré avec succès !");
      // Redirige vers une autre page ou actualise la page après la soumission
      navigate("/testimonials");
    }).catch((error) => {
      alert("Erreur lors de l'enregistrement du témoignage: ", error.message);
    });
  };

  return (
    <div>

      <h1>Ajouter un Témoignage</h1>

      {/* Champ pour le nom */}
      <input 
        type='text' 
        placeholder="Votre nom"
        value={name} 
        onChange={(e) => setName(e.target.value)} 
      />

      {/* Champ pour le témoignage */}
      <textarea 
        placeholder="Écrivez votre témoignage ici"
        value={testimonial} 
        onChange={(e) => setTestimonial(e.target.value)} 
      />

      <br />

      {/* Bouton pour enregistrer le témoignage */}
      <button onClick={saveTestimonial}>Envoyer le Témoignage</button>

    </div>
  );
}

export default AddTestimonial;
