import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../firebase';  // Import de la configuration Firebase
import { Container, Card, Button } from 'react-bootstrap';

function TeamDetails() {
  const { name } = useParams();  // Récupère le paramètre 'name' de l'URL
  const [memberDetails, setMemberDetails] = useState(null);

  useEffect(() => {
    // Récupérer les détails du membre en fonction du nom
    const db = getDatabase(app);
    const memberRef = ref(db, 'team');

    get(memberRef).then((snapshot) => {
      if (snapshot.exists()) {
        // Recherche du membre correspondant dans la base de données
        const memberData = Object.values(snapshot.val()).find(
          (member) => member.name === name
        );
        
        if (memberData) {
          setMemberDetails(memberData);
        }
      }
    });
  }, [name]);

  if (!memberDetails) {
    return <p>Chargement...</p>;
  }

  return (
    <><div className="pageTitle">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <h1 className="page-heading">Nos avocats experts</h1>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="breadCrumb">
            <a href="/">Acceuil</a> / <span>FAQs</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="parallax-section">
        <div className="container">
          <div className="attorneytop">
            <div className="row">
            <div class="col-md-4 col-sm-4">
              <img src="images/team/team-img1.jpg" class="lawimg" alt="" />
              </div>

              <div className="col-md-8 col-sm-8">
                <h2>{memberDetails.name}</h2>
                <h3>{memberDetails.position}</h3>
                <div dangerouslySetInnerHTML={{ __html: memberDetails.description }} />
                <ul className="address">
                  <li>
                    <i className="fa fa-phone"></i>
                    {memberDetails.phoneNumber}
                  </li>
                  <li>
                    <i className="fa fa-envelope-o"></i>
                    <a href={`mailto:${memberDetails.email}`}>{memberDetails.email}</a>
                  </li>
                  <li>
                    <i className="fa fa-skype"></i>jhon.attorney
                  </li>
                  <li>
                    <i className="fa fa-globe"></i>
                    <a
                      href={memberDetails.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {memberDetails.website}
                    </a>
                  </li>
                </ul>
                <ul className="list-inline social">
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-youtube" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>

                <div className="attorneydetail">
                  <h1>Personal Statement</h1>
                  <div dangerouslySetInnerHTML={{ __html: memberDetails.description }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default TeamDetails;
