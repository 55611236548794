import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ReactDOM from "react-dom";

import Home from "./pages/index";
import Admin from "./pages/admin";
import Navs from "./components/nav";
import PracticeArea from "./components/pratice";
import Team from "./components/team";
import Footer from "./components/footer";
import Write from "./components/write";
import AddTeamMember from "./components/addteam";
import Contact from "./pages/contact";
import Details from "./pages/details";
import TeamMemberDetails from "./components/TeamMemberDetails";
import Login from "./pages/admin/login";
import TeamDetails from "./components/TeamDetails";
import ReceivedMessages from "./pages/admin/received";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navs />
              <Home />
              <PracticeArea/>
              <Team/>
              <Footer/>
            </>
          }
        />
        <Route path="/admin" element={<Admin />} />
        <Route path="/dashboard" element={<><ReceivedMessages/></>}/>
        <Route path="/login" element={<><Login/></>}/>
        <Route path="/ajouter" element={<> <Write/> </>}/>
        <Route path="/ajouter-team" element={<> <AddTeamMember/> </>}/>
        <Route path="/contacte" element={<> <Navs /> <Contact/>  <Footer/> </>}/>
        <Route path="/team/:name" element={<><Navs /> <TeamDetails/> <Footer/> </>}/>
        <Route path="/teams/:name" element={<><Navs /> <TeamMemberDetails/> <Footer/> </>}/>
      </Routes>
    </Router>
  );
}

export default App;
