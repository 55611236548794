import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';

const ReceivedMessages = () => {
  const [messages, setMessages] = useState([]);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [replyText, setReplyText] = useState('');

  // Charger les messages reçus
  useEffect(() => {
    axios.get('https://cabinetbackend.onrender.com/received-messages')
      .then(response => {
        setMessages(response.data);
      })
      .catch(error => {
        console.error('Erreur lors du chargement des messages:', error);
      });
  }, []);

  // Gérer l'ouverture de la fenêtre modale
  const handleReply = (message) => {
    setSelectedMessage(message);
    setShowReplyModal(true);
  };

  // Envoyer une réponse
  const handleSendReply = () => {
    if (!selectedMessage) return;

    const reply = {
      to: selectedMessage.from,
      subject: `Re: ${selectedMessage.subject}`,
      text: replyText,
    };

    axios.post('http://localhost:3000/send-email', reply)
      .then(response => {
        alert('Réponse envoyée avec succès');
        setShowReplyModal(false);
        setReplyText('');
      })
      .catch(error => {
        alert('Erreur lors de l\'envoi de la réponse');
        console.error('Erreur:', error);
      });
  };

  return (
    <div className="container mt-4">
        <div  class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
  
    <h1 class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
        Messages
            </h1>


            
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li class="breadcrumb-item text-muted">
                                                    <a href="/" class="text-muted text-hover-primary">
                                Home                            </a>
                                            </li>

                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-500 w-5px h-2px"></span>
                    </li>
                                        
                                    <li class="breadcrumb-item text-muted">
                                                    Inbox                                            </li>
                               
                                        
                    </ul>
    </div>
    <div className="card">
      <div className="card-body p-0">
        <table className="table table-hover table-row-dashed fs-6 gy-5 my-0" id="kt_inbox_listing">
          <thead className="d-none">
            <tr>
              <th>Checkbox</th>
              <th>Auteur</th>
              <th>Titre</th>
              <th>Email</th>
              <th>Date & Heure</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => (
              <tr key={message.id}>
                {/* Checkbox */}
                <td className="ps-9">
                  <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                    <input className="form-check-input" type="checkbox" value={message.id} />
                  </div>
                </td>

                {/* Actions */}
            

                {/* Auteur */}
                <td className="w-150px w-md-175px">
                  <div className="d-flex align-items-center text-gray-900">
                    <div className="symbol symbol-35px me-3">
                      <div className="symbol-label bg-light-danger">
                      <span className="text-danger">
          {message.name ? message.name.charAt(0).toUpperCase() : "?"}
        </span>                      </div>
                    </div>
                    <span className="fw-semibold">{message.name || "Auteur inconnu"}</span>
                  </div>
                </td>

                {/* Titre */}
                <td>
                  <div className="text-gray-900 gap-1 pt-2">
                    <span className="fw-bold">{message.subject}</span>
                    <span className="d-none d-md-inline text-muted"> - {message.text.slice(0, 50)}...</span>
                  </div>
                </td>
                <td>
                  <div className="text-gray-900 gap-1 pt-2">
                    <span className="fw-bold">{message.to}</span>
                  </div>
                </td>

                {/* Date */}
                <td className="w-100px text-end fs-7 pe-9">
                  <span className="fw-semibold">{new Date(message.date).toLocaleString()}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

      {/* Modale pour répondre au message */}
      <Modal show={showReplyModal} onHide={() => setShowReplyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Répondre à {selectedMessage?.from}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Sujet</Form.Label>
              <Form.Control type="text" value={`Re: ${selectedMessage?.subject}`} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
            Annuler
          </Button>
          <Button variant="success" onClick={handleSendReply}>
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReceivedMessages;
