import React, { useState } from 'react';
import { app, db } from '../firebase';  // Utilisez des imports nommés
import { getDatabase, ref, set, push } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';  // Import de ReactQuill
import 'react-quill/dist/quill.snow.css';  // Import du style par défaut de ReactQuill

function AddTeamMember() {
  const navigate = useNavigate();

  // Initialiser les valeurs d'état pour les champs de formulaire
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");  // Nouveau champ téléphone
  const [email, setEmail] = useState("");  // Nouveau champ email
  const [description, setDescription] = useState("");  // Nouveau champ description
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");

  const saveTeamMember = async () => {
    const db = getDatabase(app);
    const newMemberRef = push(ref(db, "team"));
    
    try {
      await set(newMemberRef, {
        name: name,
        position: position,
        imageUrl: imageUrl,
        socialLinks: {
          twitter: twitter,
          facebook: facebook,
          linkedin: linkedin
        },
        phoneNumber: phoneNumber,  // Enregistrement du numéro de téléphone
        email: email,  // Enregistrement de l'email
        description: description,  // Enregistrement de la description
      });
      setAlertMessage("Membre de l'équipe ajouté avec succès !");
      setAlertVariant("success");
      navigate("/");
    } catch (error) {
      setAlertMessage("Erreur lors de l'ajout du membre: " + error.message);
      setAlertVariant("danger");
    }
  };

  return (
    <Container className="my-5">
      <h1>Ajouter un Membre à l'Équipe</h1>

      {/* Affichage d'une alerte en cas de succès ou d'erreur */}
      {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>}

      <Form>
        {/* Champ pour le nom */}
        <Form.Group className="mb-3">
          <Form.Label>Nom</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Nom du membre"
            value={name} 
            onChange={(e) => setName(e.target.value)} 
          />
        </Form.Group>

        {/* Champ pour le titre du poste */}
        <Form.Group className="mb-3">
          <Form.Label>Poste</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Poste du membre"
            value={position} 
            onChange={(e) => setPosition(e.target.value)} 
          />
        </Form.Group>

        {/* Champ pour l'URL de l'image */}
        <Form.Group className="mb-3">
          <Form.Label>URL de l'image</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="URL de l'image du membre"
            value={imageUrl} 
            onChange={(e) => setImageUrl(e.target.value)} 
          />
        </Form.Group>

        {/* Champs pour les liens sociaux */}
        <Form.Group className="mb-3">
          <Form.Label>Twitter</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Lien vers Twitter"
            value={twitter} 
            onChange={(e) => setTwitter(e.target.value)} 
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Facebook</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Lien vers Facebook"
            value={facebook} 
            onChange={(e) => setFacebook(e.target.value)} 
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>LinkedIn</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Lien vers LinkedIn"
            value={linkedin} 
            onChange={(e) => setLinkedin(e.target.value)} 
          />
        </Form.Group>

        {/* Nouveau champ pour le numéro de téléphone */}
        <Form.Group className="mb-3">
          <Form.Label>Numéro de téléphone</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Numéro de téléphone du membre"
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
          />
        </Form.Group>

        {/* Nouveau champ pour l'email */}
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Email du membre"
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </Form.Group>

        {/* Nouveau champ pour la description (ReactQuill) */}
        <Form.Group className="mb-5">
          <Form.Label>Description</Form.Label>
          <ReactQuill 
            value={description} 
            onChange={setDescription} 
            placeholder="Entrez la description du membre"
          />
        </Form.Group>

        {/* Bouton pour enregistrer le membre de l'équipe */}
        <Button 
          variant="primary" 
          onClick={saveTeamMember} 
          className="w-100"
        >
          Ajouter le Membre
        </Button>
      </Form>
    </Container>
  );
}

export default AddTeamMember;
