import React, { useState, useEffect } from 'react';
import CountUp from "react-countup";
import "font-awesome/css/font-awesome.min.css";
function Home() {
    const phrases = ["Divorce", "Diffamation", "Blessure", "Accident", "Morsure d'animal"];
  const [currentText, setCurrentText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText(phrases[index].slice(0, currentText.length + 1));
      if (currentText === phrases[index]) {
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
          setCurrentText("");
        }, 2000);
      }
    }, 150);

    return () => clearInterval(interval);
  }, [currentText, index]);
    const counters = [
        {
          icon: "fa-users",
          to: 999,
          text: "Client de confiance",
          speed: 1000,
        },
        {
          icon: "fa-university",
          to: 1512,
          text: "Cas de réussite",
          speed: 2000,
        },
        {
          icon: "fa-laptop",
          to: 1756,
          text: "Étude de cas",
          speed: 3000,
        },
        {
          icon: "fa-trophy",
          to: 101,
          text: "Awards",
          speed: 4000,
        },
      ];
    return(
<>

<div id="home2" class="parallax-section"> 
  <div class="container">
    <div class="slide-text">
    <h3 class="typewrite">
      Ce que nous pouvons faire ?{' '}
      <span style={{ color: 'red' }}>{currentText}</span>
    </h3>
      <h1>Bienvenue sur le site du Cabinet des conseillés juridiques en gestion de patrimoine de droit </h1>
      <p>votre expert en droit. Nous vous offrons un service juridique de qualité pour vous accompagner dans toutes vos démarches légales.</p>
      <a href="#about" class="btn btn-default section-btn">commencer</a> </div>
  </div>
</div>

<div class="howitwrap" id="about">
  <div class="container">
  	<div class="row">
    	<div class="col-md-4"><img src="images/team/11492701.png" alt="" /></div>
        <div class="col-md-8"><div class="stcontent"> 
    
    <div class="section-title">
      <h3>Bienvenue sur le cabinet juridique des <span>Avocats & Conseiller</span></h3>
      <p>Nous intervenons dans divers domaines du droit, notamment : droit pénal, droit des affaires, droit de la famille, droit des contrats, et bien plus encore.</p>
    </div>
    
    <ul class="howlist">
      <li>
        <div class="howbox">
          <div class="iconcircle"><i class="fa fa-university" aria-hidden="true"></i></div>
          <h4>Protégez vos droits</h4>
          <p>Chez Maitre Francisco Dasilva, nous croyons fermement que chaque individu mérite que ses droits soient respectés et protégés. Que vous soyez confronté à une injustice, une discrimination ou des violations de vos libertés fondamentales, nous mettons tout en œuvre pour défendre vos droits avec compétence et détermination. </p>
        </div>
      </li>
    
      <li>
        <div class="howbox">
          <div class="iconcircle"><i class="fa fa-handshake-o" aria-hidden="true"></i></div>
          <h4>Traitement médical</h4>
          <p>Un traitement médical de qualité est essentiel pour récupérer après un accident ou une blessure, mais il peut parfois être négligé ou mal administré. </p>
        </div>
      </li>

      <li>
        <div class="howbox">
          <div class="iconcircle"><i class="fa fa-male" aria-hidden="true"></i></div>
          <h4>Nous nous battons pour la justice</h4>
          <p>La justice ne doit pas être un privilège, mais un droit pour tous. À [Nom de votre cabinet], nous nous engageons pleinement à lutter pour la justice pour nos clients. Qu'il s'agisse de défendre vos droits, d'obtenir réparation pour un tort subi ou de garantir un procès équitable, nous sommes là pour vous.</p>
        </div>
      </li>
    </ul>
  </div></div>
    </div>
  </div>
  
  
</div>

<div id="counter">
      <div className="container">
        <div className="row">
          {counters.map((counter, index) => (
            <div
              className="col-md-3 col-sm-3 col-xs-12 counter-item"
              key={index}
            >
              <div className="counterbox">
                <div className="counter-icon">
                  <i className={`fa ${counter.icon}`} aria-hidden="true"></i>
                </div>
                <span className="counter-number">
                  <CountUp end={counter.to} duration={counter.speed / 1000} />
                </span>
                <span className="counter-text">{counter.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
</>
    );
    
}

export default Home;