import react from "react";

function Footer() {
  return (
    <>
    <div class="our-clients">
  <div class="container">
    <div class="owl-clients">
     
    </div>
  </div>
</div>
      <div class="site-footer">
        <div class="footer-top-area">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="footer-lwf">
                  <h3 class="footer-logo">
                    <img src="images/logo.png" alt="Lawyer & Attorney" />
                  </h3>
                  <p>
                  Notre cabinet d'avocat offre des services juridiques de qualité, en mettant l'accent sur l'écoute, la rigueur et l'engagement pour la défense de vos droits. Que ce soit en matière de droit des affaires, droit pénal, droit de la famille, ou toute autre spécialité juridique, nous vous accompagnons avec professionnalisme et discrétion.
                  </p>
                  <ul class="footer-contact">
                    <li>
                      <i class="fa fa-phone"></i> +44 732123293
                    </li>
                    <li>
                      <i class="fa fa-envelope"></i> contact@cabinet-juridique.online
                    </li>
                    <li>
                      <i class="fa fa-fax"></i> +33 780933687
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="footer-lwf footer-menu">
                  <h3 class="footer-lwf-title">Liens rapides
                  </h3>
                  <ul>
                    <li>
                      <a href="/">Acceuil</a>
                    </li>
                    <li>
                      <a href="/about">À propos de l'entreprise</a>
                    </li>
                  
                    <li>
                      <a href="/contacte">Nous contacter</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="footer-lwf footer-menu">
           
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="footer-lwf">
                  <h3 class="footer-lwf-title">Heures d'ouverture</h3>
                  <ul class="open-hours">
                    <li>
                      <span>Du lundi au vendredi:</span>{" "}
                      <span class="text-right">09h30 à 17h30</span>
                    </li>
                    <li>
                      <span>Dim:</span> <span class="text-right">Fermé</span>
                    </li>
                  </ul>
                  <div class="newsletter">
                    <form>
                      <input
                        type="text"
                        placeholder="Enter your email"
                        value=""
                        class="news-input"
                      />
                      <button type="submit" value="" class="news-btn">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom-area">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-md-6">
                Copyright &copy; 2024 <span>Cabinet juridique</span>. All Rights
                Reserved
              </div>
              <div class="col-md-12 col-md-6 text-right">
                Design &amp; Development By:{" "}
                <a href="#" target="_blank">
                  PDG-G
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;