import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import de l'authentification
import { getDatabase } from "firebase/database"; // Import de la base de données
import { getAnalytics } from "firebase/analytics"; // Import des analytics
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore"; // Import Firestore

// Votre configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCM7tHDI5OEv6cdvq0X6pYFDaGgBhQl0K0",
    authDomain: "avocats-5ecb0.firebaseapp.com",
    projectId: "avocats-5ecb0",
    databaseURL: "https://avocats-5ecb0-default-rtdb.firebaseio.com",
    storageBucket: "avocats-5ecb0.firebasestorage.app",
    messagingSenderId: "198533663795",
    appId: "1:198533663795:web:4e1afc8da896e5b671a746",
    measurementId: "G-LNMBT7K6Y8",
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);

// Initialisation des services Firebase
const db = getDatabase(app); // Base de données
const auth = getAuth(app); // Authentification
const analytics = getAnalytics(app); // Analytics (optionnel)
const firestore = getFirestore(app); // Firestore (optionnel)

// Exporter l'application et les services nécessaires
export { app, db, auth, analytics, firestore };
