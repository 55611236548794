import React, { useState } from "react";
import { Button, Form, Navbar, Nav, Container, Card, Alert } from "react-bootstrap";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Importer useNavigate

function Login() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });

  const navigate = useNavigate(); // Initialiser le navigate

  const showAlert = (message, variant) => {
    setAlert({ show: true, message, variant });
    setTimeout(() => setAlert({ show: false, message: "", variant: "" }), 5000);
  };

  const handleRegister = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      showAlert("Inscription réussie", "success");
      navigate("/dashboard"); // Rediriger vers la page d'ajout de team après inscription réussie
    } catch (error) {
      showAlert(error.message, "danger");
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      showAlert("Connexion réussie", "success");
      navigate("/dashboard"); // Rediriger vers la page d'ajout de team après connexion réussie
    } catch (error) {
      console.error(error); // Affiche l'erreur dans la console
      showAlert("Erreur de connexion : " + error.message, "danger"); // Affiche un message d'erreur détaillé
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      showAlert("Déconnexion réussie", "success");
    } catch (error) {
      showAlert(error.message, "danger");
    }
  };

  return (
    <div>
      {/* Barre de navigation */}
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand>Admin Dashboard</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#">Accueil</Nav.Link>
              <Nav.Link href="#">Ajouter un avocat</Nav.Link>
              <Nav.Link href="#">Liste des avocats</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Formulaire */}
      <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Card style={{ width: "50rem" }}>
          <Card.Body>
            <Card.Title className="mb-4 text-center">
              {isRegistering ? "Inscription" : "Connexion"}
            </Card.Title>

            {/* Alert */}
            {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}

            <Form>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group> <br/>

              {isRegistering ? (
                <Button variant="primary" className="w-100" onClick={handleRegister}>
                  S'inscrire
                </Button>
              ) : (
                <Button variant="primary" className="w-100" onClick={handleLogin}>
                  Se connecter
                </Button>
              )}
            </Form>

            <Button
              variant="link"
              className="mt-3 w-100 text-center"
              onClick={() => setIsRegistering(!isRegistering)}
            >
              {isRegistering
                ? "Vous avez déjà un compte ? Connectez-vous"
                : "Pas de compte ? Inscrivez-vous"}
            </Button>
          </Card.Body>
        </Card>
      </Container>

      {/* Pied de page */}
      <footer className="bg-light text-center py-3 mt-4">
        <small>© 2024 Mon Application. Tous droits réservés.</small>
      </footer>
    </div>
  );
}

export default Login;
